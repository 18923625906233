import React from "react"
import { Paragraph, Link } from "evergreen-ui"
import StartButton from "../components/StartButton"

export default ({ location }) => {
  return (
    <>
      <Paragraph size={500} marginTop="default">
        Vážený servisný partner,
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        ďakujeme za Váš záujem stať sa partnerským servisom spoločnosťi Goodyear
        v rámci internetového obchodu. Partnerské servisy ponúkajú svoje služby
        konečným zákazníkom, ktorí využívajú elektronický obchod náležiaci
        spoločnosti Goodyear. V rámci spolupráce dostanete za každú transakciu
        odmenu za montáž a manipulačný poplatok za pneumatiku. Zoznámte sa
        prosím s priloženým{" "}
        <Link
          href="/manuals/goodyear.sk_manual2021.pdf"
          target="_blank"
          size={500}
        >
          manuálom
        </Link>{" "}
        pre viac informácií o programe.
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Pre zaregistrovanie sa do programu dokončite všetky kroky online
        registrácie.
      </Paragraph>

      <Paragraph size={500} marginTop="default">
        Po ukončení procesu registrácie dostanete Vy aj spoločnosť Goodyear
        email obsahujúci všetky údaje z registrácie. Potom spoločnosť Goodyear
        Vašu registráciu overí a po overení Vám oznámi, že ste boli aktivovaní
        ako partnerský servis spoločnosti Goodyear v rámci internetového
        obchodu.
      </Paragraph>

      <Paragraph size={500} marginTop="default" marginBottom="default">
        Ešte raz ďakujeme za Váš záujem o program spoločnosti Goodyear. Dúfame,
        že Vás už čoskoro privítame ako partnerský servis.
      </Paragraph>
      <StartButton search={location.search} />
    </>
  )
}
